/* eslint-disable max-len */
/* eslint-disable no-console */
import { MDXProvider } from "@mdx-js/react";
import cx from "classnames";
import { Link, PageProps, graphql } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { MDXRenderer } from "gatsby-plugin-mdx";
import React, { ReactElement } from "react";
import { Helmet } from "react-helmet";
import { PhotoGallary1, PhotoGallary2, ReferenceLinks, ThumbImg, getServRefElement, mdxcomponents } from "./common";
import { CallUs, SEO, SiteLayout, TasFollowButtons, TasReactButtons, TasShareButtons } from "../components";
import ViewMoreFB from "../components/morefblink";
import { ServiceCard } from "../components/servicecard";

const _whatsAppMessage = (pageurl:String,msg:String) => encodeURI(`${pageurl}

Hello TAS.  
Please share the cost details for ${msg}`);

const _sendQuoteButton = (pageurl:String, msg:String, style:string) => 
    <div className="m-4">
        <a href={ `https://wa.me/919497454647?text=${_whatsAppMessage(pageurl, msg)}` } 
            className={ style }
            target="_blank" rel="noreferrer"> 
            <span className="inline-block">Get Quote ₹ </span>
        </a>
    </div>;

const _getMdxComponents = (pageurl:string, parentprops:any) =>{
    return { ...mdxcomponents, 
        ...getServRefElement(parentprops),
        askprice: (props:any) => 
            _sendQuoteButton(pageurl, props.msg, "flex-none border focus:ring-4font-medium rounded-lg text-sm px-3 py-2.5 text-center mr-2 mb-2 bg-gray-600 text-white border-gray-400 hover:bg-gray-700 hover:border-gray-700 focus:ring-gray-800")
    };
};



const NextPrevNav = ({ pageContext } ) =>  
    <div className="flow-root mt-6 justify-between">

        <div className="float-right text-green-800"> 
            <a className="p-2 rounded-lg border border-gray-600 text-right place-items-top flex mr-8 text-gray-500 transition-colors duration-200 hover:text-gray-900 dark:text-gray-400 dark:hover:text-white" 
                href={ `/${pageContext.nextnode.slug}` }>
                <div className="flex flex-row flex-grow">
                    <GatsbyImage image={ getImage(pageContext.nextnode.heroimage.thumb) } 
                        className="flex w-12 rounded mr-1" />
                    <p className="flex flex-grow"><span>Next<br/> <span className="text-xs">{ pageContext.nextnode.title }</span> </span><span aria-hidden="true" className="ml-2 text-xl font-bold">→</span></p>
                </div>
            </a>
        </div>
   
        <div className="float-left text-green-600">
            <a className="p-2 rounded-lg border border-gray-600 text-left place-items-top flex mr-8 text-gray-500 transition-colors duration-200 hover:text-gray-900 dark:text-gray-400 dark:hover:text-white" 
                href={ `/${pageContext.prevnode.slug}` }>
                <div className="flex flex-row flex-grow">
           
                    <span aria-hidden="true" className="mr-2 text-xl font-bold" >←</span><span>Previous<br/><span className="text-xs w-min">{ pageContext.prevnode.title }</span></span>
                    <GatsbyImage image={ getImage(pageContext.prevnode.heroimage.thumb) } 
                        className="flex w-12 rounded ml-1" />
                </div>
            </a>
        </div>

    </div>;

const _fullUrl = (site:string, path:string) => {
    const rEds = site.endsWith("/");
    const pSts = path.startsWith("/");

    return site + ((rEds || pSts) ? "":"/") + path;
};


/**
 * Interface for the Pricing Page props.
 */
//  type IPricingPageProps = TestableComponentInterface;

/**
  * Pricing page of the site.
  *
  * @param {PageProps<IPricingPageProps>} props - Props injected to the component.
  * @return {React.ReactElement}
  */
const ServicePage = (props: any): ReactElement => {

    const {
        data,
        pageContext
    } = props;

    const {
        service,
        site,
        refprods,
        refservice,
        refdocs
    } = data;

    const { frontmatter } = service;
    const refsrvs = (refservice ? refservice.edges : null);
    const refprodslist = (refprods ? refprods.edges : null);
    const refdocslist =  (refdocs ? refdocs.edges : null);

    const pagestyles = cx(`text-xl tracking-wider leading-relaxed mt-6 font-light ${frontmatter.pagestyles}`);

    const siteroot = (frontmatter.model == "project") ? site.siteMetadata["projecturl"] : site.siteMetadata.siteUrl;
    const pageimage = _fullUrl(siteroot, frontmatter.heroimage ?  frontmatter.heroimage.file.childImageSharp.gatsbyImageData.images.fallback.src : null);

    var costdata  = null;
    let costs;

    if(service.frontmatter.pricerange){
        costs = service.frontmatter.pricerange.split("-");
        costdata = (costs.length > 1) ? `₹ ${costs[0]} to  ₹ ${costs[1]}` : `Starting from ₹ ${costs[0]}/-`;
    }

    const { originalPath, pagefaqs } = pageContext;
    const pageurl = siteroot + originalPath;
    
    const { meta = {}, priceinfo } = frontmatter;
    const _hasMeta = meta != null;
    const pagedesc = _hasMeta && meta.desc ? meta.desc : frontmatter.pagedescription ? frontmatter.pagedescription : frontmatter.description;
    const _descAddKeywords = _hasMeta && meta.desc ? false : true;

    const jsondata = {
        "@context": "https://schema.org/",
        "@type": "Product",
        "name": pageContext.pagetitle,
        "image": pageimage,
        "description": pagedesc,
        "brand": {
            "@type": "Brand",
            "name": "T.A.S Detailing"
        },
        "offers": {
            "@type": "Offer",
            "url": pageurl,
            "priceCurrency": "INR",
            "price": costs == null || costs.length == 0 ? 0 : costs[0],
            "availability": "https://schema.org/InStock",
            "itemCondition": "https://schema.org/NewCondition"
        }
    };
  

    //
    // Related products list
    // 
    const RelatedProducts =  (refprodslist && refprodslist.length > 0) ?
        <div className="flex  flex-col flex-grow w-full  flex-wrap place-items-start justify-center gap-2 p-4">
            <p className="flex flex-row flex-grow ">
                <span className="underline text-2xl">Related Products</span>
            </p>
            <div className="flex flex-wrap flex-row flex-grow   items-stretch  place-items-center gap-4 mt-6">
                { refprodslist.map((obj: any, index: number) => (
                    <ServiceCard data-testid={ index } key={ index } data={ obj.node } siteroot={ site.siteMetadata.siteUrl } />
                )) }
            </div>
        </div> : null;

    // 
    // Related Services list
    // 
    const RelatedServices = (refsrvs && refsrvs.length > 0) ?
        <div className="flex  flex-col flex-grow w-full  flex-wrap place-items-start justify-center gap-2 p-4">
            <p className="flex flex-row flex-grow ">
                <span className="underline text-2xl">Related Services</span>
            </p>
            <div className="flex flex-wrap flex-row flex-grow  items-stretch place-items-center gap-4 mt-6">
                { refsrvs.map((obj: any, index: number) => (
                    <ServiceCard data-testid={ index } key={ index } data={ obj.node }  siteroot={ site.siteMetadata.siteUrl }/>
                )) }
            </div>
        </div>
        : null;
  
    const relatedLists = frontmatter.model == "product" ? [RelatedProducts, RelatedServices] : [RelatedServices, RelatedProducts];

    const mdxcomps = _getMdxComponents(pageurl, props);

    // image tags
    // const { photosmeta, photos, heroimage }: {photosmeta: string[],photos:Array<any> , heroimage:any  }  = frontmatter;
   
    const canonicals:Array<any> = [];
    pageContext.pagecopies.map((copy: any) => {
        if(copy.slug !== pageContext.slug.slug) canonicals.push(copy);
    });
    const fulltitle = _hasMeta && meta.title ? meta.title : "TAS (Travancore Auto Spa) - Service : " + pageContext.pagetitle;
    
    return (
        <SiteLayout data-testid={ `service-${frontmatter.uid}-site-layout` } className="text-gray-400 ">
            <Helmet>
               
                <meta name="title" content={ fulltitle } />
                <meta name="description" content={ _descAddKeywords ? pagedesc + " " + frontmatter.hashkeywords : pagedesc } />

                { (_hasMeta && meta.keywords) &&   
                    <meta name="keywords" content={ meta.keywords }/>  
                }

                { /* <!-- Open Graph / Facebook --> */ }
                <meta property="og:type" content="website" />
                <meta property="og:url" content={ pageurl } />
                <meta property="og:title" content={ fulltitle } />
                { /* <meta property="og:description" content={ pagedesc + " " + frontmatter.hashkeywords } /> */ }
                <meta property="og:image" content={ pageimage } />

                { /* <!-- Twitter --> */ }
                { /* <meta property="twitter:card" content="summary_large_image" /> */ }
                <meta property="twitter:url" content={ pageurl } />
                <meta property="twitter:title" content={ fulltitle } />
                { /* <meta property="twitter:description" content={ pagedesc + " " + frontmatter.hashkeywords } /> */ }
                <meta property="twitter:image" content={ pageimage } />

                { canonicals && canonicals.map((copy: any, index: number) => {
                    console.log(`${copy.slug}`);
                    return <link rel="canonical" key={ index } href={ _fullUrl(siteroot, copy.slug ) } />;
                }) }

                <script type="application/ld+json">
                    { JSON.stringify(jsondata) }
                </script>

               
                { /* { photos && photos.map((obj: any, index: number) => (
                    <meta key={ index } property="og:image" content={ _fullUrl(siteroot, obj.childImageSharp.gatsbyImageData.images.fallback.src) } />
                )) }  */ }
                        
            </Helmet>
            <SEO title={ _hasMeta && meta.title ? meta.title : pageContext.pagetitle } description={ _descAddKeywords ? pagedesc + " " + frontmatter.hashkeywords : pagedesc  }
                meta={
                    [
                        {
                            content: "summary_large_image",
                            name: "twitter:card"
                        }
                    ]
                }/>
            <TasShareButtons pageContext={ pageContext }/>  
     
            <div className="grid grid-flow-row sm:grid-cols-1 auto-rows-max place-items-center">
                <div className="group">
                    <div className="flex flex-row grow flex-wrap place-items-center justify-center">
                        <div className="grow content-end m-4  justify-center  place-items-center  text-center">
                            <p className="group-hover:scale-125 heading text-4xl md:text-6xl font-light font-sans md:leading-tight dark:text-amber-400  transition-all duration-500 ease-in-out transform">
                                { frontmatter.title }
                            </p>
                            <h2 className="text-xl text-gray-400 mt-2 text-center">
                                { frontmatter.description }
                            </h2>
                        </div>
                    </div>
                    <div className="grow content-end justify-center  place-items-center pb-10">
                        <div className="relative max-w-4xl mx-auto px-4">
                            <GatsbyImage
                                image={ getImage(frontmatter.heroimage.file) }
                                alt={ frontmatter.heroimage.title }
                                className="rounded object-cover w-full group-hover:scale-125 transition-all duration-500 ease-in-out transform" />
                        </div>
                    </div>
                </div>
            </div>
            <div className="py-4">
                <div className="w-full border-t  border-gray-800">
         
                </div>
            </div>
            <div className="relative flex-grow px-4 dark:text-gray-300">
                <CallUs />
            </div>

            <div className="flex flex-col place-items-center justify-center  pb-6">
        
                <div className="grow md:w-4/5 relative mx-auto px-4 text-gray-300 font-light justify-center  place-items-center m-4">
                    <div className="flex flex-wrap justify-center place-content-center place-items-center flex-grow gap-2">
                        { frontmatter.tags ? frontmatter.tags.map((tag: string, i: number) => (
                            <span key={ i } className="tagbadge">
                                { tag }
                            </span>
                        )) : null }
                    </div>
                    { /* <button type="button" class="py-2.5 px-5 mr-2 mb-2 text-sm font-medium text-gray-900 bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-2 focus:ring-blue-700 focus:text-blue-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700">Alternative</button> */ }

                    <NextPrevNav { ...props }/>

                    <div className={ pagestyles }>

         
                        <MDXProvider components={ mdxcomps }>
                            <MDXRenderer frontmatter={ service.frontmatter }>{ service.body }</MDXRenderer>
                        </MDXProvider>
                    </div>
                    { frontmatter.photos &&
                        <div className="flex grow items-stretch place-content-center place-items-center">
                            <PhotoGallary1 frontmatter={ frontmatter } />
                        </div>
                    }
                    { frontmatter.thumbgallery &&
                        <section className="thumbgal  place-content-center place-items-center justify-center">
                            <PhotoGallary2  frontmatter={ frontmatter }/>
                        </section>
                    }
                    { /* recommeded interval */ }
                    { frontmatter.serviceinterval ? (
                        <p className="text-xl ml-4 mt-6">
                            <span className="text-amber-500">Recommended service interval </span>:{ " " + frontmatter.serviceinterval }
                        </p>
                    ) : (
                        ""
                    ) }

                    { costdata ? (
                        <p className="ml-4 mt-2">
                            { priceinfo && 
                                priceinfo.map((text: string, i: number) => (
                                    <p className="text-xl" key={ i }>
                                        { text }
                                    </p>
                                ))
                            }
                            <p className="text-xl">
                                <span className="text-amber-500">*Price Range </span>:{ " " }
                                { costdata }
                            </p>
                            <span className="text-neutral-500 text-sm">
                                <i>
                                    * Conditions apply, Tentative pricing only,
                                    actual service cost depends on the surface
                                    condition, labour, products involved. Please
                                    bring the vehicle for FREE inspection and
                                    estimation.
                                </i>
                            </span>
                        </p>
                    ) : (
                        ""
                    ) }
                    { /** has lined faqs ? */ }
                    {
                        (pagefaqs && pagefaqs.length > 0) && 
                            <>
                                <section className="p-4">
                                    <p className="text-xl font-light">
                                        Read FAQs
                                    </p>
                                    <p className="flex flex-wrap">
                                        {
                                            pagefaqs.map((fcat:any, i:number) => 
                                                (<a className="p-4 pt-2 underline" key={ i } href={ "/"+ fcat.slug }> { fcat.name }</a> )
                                            )
                                        }
                                    </p>
                                </section>
                            </>
                    }
                  
                    { /* refdocslist Any link reference to blog/pages 
                    */ }
                    
                    {
                        (frontmatter.refuids??"").length > 0 &&
                            <section className="pt-10">
                                <p className="text-2xl font-light">
                                    Read related pages
                                </p>
                                <p  className="p-4 flex flex-wrap flex-1">
                                    <ReferenceLinks uid={ frontmatter.refuids } parentprops={ props }/>
                                </p>
                            </section>
                    }
                    
                    { /* hashkeywords */ }
                    { frontmatter.hashkeywords ? (
                        <p className="flex flex-wrap flex-grow justify-center place-content-center place-items-center gap-1 mt-6">
                            {
                                frontmatter.hashkeywords.split(",").map((tag:string, i:number) => <span key={ i } className="tagbadge">{ tag }</span>)
                            }
                        </p>
                    ) : (
                        ""
                    ) }
                    { frontmatter.pageendfreetext &&
                        <p className="m-2 text-center text-gray-600">{ frontmatter.pageendfreetext }</p>
                    }
                    { frontmatter.model == "project" &&
                        <ViewMoreFB/>
                    }
         
                    <div className="w-full border-t  border-gray-800 mt-6 mb-6"></div>    
                    <NextPrevNav { ...props }/> 

                    { /** Add related products and/or services */ }
                    { relatedLists }

                </div>
            </div>
      

          
            <><div className="flex flex-row  w-full  flex-col-1 grow items-stretch flex-wrap place-items-center justify-center gap-2 p-4">
                <p className="text-center text-xl md:text-2xl xl:text-2xl subpixel-antialiased font-light text-gray-400">
                    Contact us now
                </p>
            </div><CallUs />
            </>
            <TasFollowButtons/>
            <div  className="flex flex-row  w-full  place-items-center justify-center ">
                <TasReactButtons pageContext={ pageContext }/>  
            </div>
        </SiteLayout>
    );
};


export default ServicePage;

export const pageQuery = graphql`
query servicesdetails($id: String, $relatedsrvs: [String], $relatedprods: [String], $refuids: [String]) {
  site: site {
    siteMetadata {
      siteUrl
      title
      serviceurl
      projecturl
      blogurl
      author
    }
  }
  service: mdx(id: {eq: $id}) {
    id
    body
    slug
    frontmatter {
      model
      uid
      title
      subtitle
      heroimage {
        title
        file {
          childImageSharp {
            gatsbyImageData
          }
        }
      }
      meta {
        desc
        title
        keywords
      }
      description
      pagedescription
      style
      order
      pricerange
      priceinfo
      relatedservices
      relatedprods
      tags
      alternatives
      serviceinterval
      hashkeywords
      thumbgallery
      pagestyles
      photosmeta
      refuids
      pageendfreetext
      photos {
        relativePath
        name
        childImageSharp {
          gatsbyImageData(formats: [WEBP, JPG])
        }
      }
    }
    fields {
      slug
    }
  }
  refservice: allMdx(
    filter: {frontmatter: { uid: {in: $relatedsrvs} }}
  ) {
    edges {
      node {
        id
        slug
        frontmatter {
          model
          title
          description
          uid
          heroimage {
            title
            file {
              childImageSharp {
                gatsbyImageData(width: 220)
              }
            }
            thumb {
              childImageSharp {
                gatsbyImageData(width: 220)
              }
            }
          }
        }
        fields {
          slug
        }
      }
    }
  }
  refprods: allMdx(filter:{frontmatter:{uid:{in: $relatedprods}}}) {
    edges {
      node {
        id
        slug
        frontmatter {
          model
          title
          description
          uid
          heroimage {
            title
            file {
              childImageSharp {
                gatsbyImageData(width: 220)
              }
            }
            thumb {
              childImageSharp {
                gatsbyImageData(width: 220)
              }
            }
          }
        }
        fields {
          slug
        }
      }
    }
  }
  refdocs: allMdx(
    filter: {frontmatter: { uid: {in: $refuids} }}
  ) {
    edges {
      node {
        id
        slug
        frontmatter {
          model
          title
          description
          uid
          heroimage {
            title
            file {
              childImageSharp {
                gatsbyImageData(width: 220)
              }
            }
            thumb {
              childImageSharp {
                gatsbyImageData(width: 220)
              }
            }
          }
        }
        fields {
          slug
        }
      }
    }
  }
}

 `;


