/* eslint-disable max-len */
import { faPhone } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import React, { ReactElement } from "react";

const tplobj = {
    tas : "TAS, Travancore Auto Spa, Detailing Studio"
};

function _templateExc(tpl: string, params: any): string {
    const func = new Function(
        ...Object.keys(params),
        "return `" + tpl + "`;"
    );
    return func(...Object.values(params));
}

export const mdxcomponents = {
    wrapper: ({ children }) => <>{ children }</>,
    h1: (props: any) => (
        // eslint-disable-next-line jsx-a11y/heading-has-content
        <h1 style={ { fontSize: "60px", fontWeight: "bold" } } { ...props } />
    ),
    h2: (props: any) => (
        // eslint-disable-next-line jsx-a11y/heading-has-content
        <h2 style={ { fontSize: "40px", fontWeight: "bold" } } { ...props } />
    ),
    h3: (props: any) => (
        // eslint-disable-next-line jsx-a11y/heading-has-content
        <h3 style={ { fontSize: "30px", fontWeight: "bold" } } { ...props } />
    ),
    h4: (props: any) => (
        // eslint-disable-next-line jsx-a11y/heading-has-content
        <h4 style={ { fontSize: "20px", fontWeight: "bold" } } { ...props } />
    ),
    h5: (props: any) => (
        // eslint-disable-next-line jsx-a11y/heading-has-content
        <h5 style={ { fontSize: "20px", fontWeight: "bold" } } { ...props } />
    ),
    ol: (props: any) => <ol style={ { listStyle: "auto" } } { ...props } />,
    ul: (props: any) => <ul style={ { listStyle: "auto" } } { ...props } />,
    li: (props: any) => <li { ...props } />,
    p: (props: any) => <p className="mt-4 mb-4" { ...props } />,
    strong: (props: any) => (
        <strong style={ { fontWeight: "900", lineHeight: 1.6 } } { ...props } />
    ),
    Link,
    div: (props: any) => <div id="customdivmdx" { ...props } />,
    youtube: (props:any)=>(
        <iframe  { ...props } className="aspect-video"
            title={ props.title } 
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
            scrolling="no" allowTransparency={ true } allowFullScreen={ true }>
        </iframe>
    ),
    imgleft: (props: any) => (
        <div className="float-left p-6">
            <img alt={ props.alt } { ...props } className="rounded"/>
        </div>
    ),
    tasstrong: (props: any) => (
        <span className="text-2xl goldtxt" { ...props }></span>
    ),
    tastitle: (props: any) => (
        <p className="mt-10 mb-6"><span className="text-2xl font-light border-b text-amber-400 border-gray-600" { ...props } /></p>
    ),
    imgpara: (props: any) =>{
        var { src, alt, title } = props;
        // eslint-disable-next-line no-redeclare
        var { src, alt, ...rest } = props;
        const float = props.float ? props.float : "left";
        const ImgEle = <img className="rounded pl-3 pr-3 pt-3"  src={ src } title={ title } alt={ alt } style={ { maxHeight: "250px" } }/>;
        const ParaEle =  <p className="m-4" { ...rest } />;
        const children = float == "left" ? [ ImgEle, ParaEle ] :  [ParaEle , ImgEle];
        return (
            <div className="flex flex-row place-items-center md:place-items-end mt-6 mb-6">
                { children }
            </div>
        );
    },
    gallary: (props: any) => {
      
        // console.log(props.data)
  
        return <div className="grid grid-cols-2 grow md:grid-cols-4 items-stretch mt-10 mb-10 pl-4 pr-4 justify-center place-items-center place-content-center gap-2 w-full">
            { props.data.map((obj: any, index: number) => (
                <div className="w-full p-1" key={ index }>
                    <img  alt={ props.alt }  className={ `${obj.class} imgzoom block object-cover object-center w-full h-full rounded-lg` }
                        { ...obj } />
                </div>
            )) }
        </div>;
    },

    qanswer: (props:any) => {
        return (
            <div className="flex flex-col mx-2 my-6">
                <p>
                    <span className="text-4xl font-semibold" style={ { color: "#ed8936" } }>Q.</span>
                    <span className="ml-4 text-2xl" style={ { color: "#f6ad55" } }> { props.title } </span>
                </p> 
                <article className="flex flex-wrap text-ellipsis break-normal"  { ...props }/>
            </div>
        );
    },
    parahead: (props:any) => {
        return (
            <div className="flex flex-col mx-2 my-6">
                <p className="text-2xl" style={ { color: "#f6ad55" } }>
                    { props.title }
                </p> 
                <article className="flex flex-wrap text-ellipsis break-normal"  { ...props }/>
            </div>
        );
    },
    callnow : (props:any) => {
        return (
            <div className="m-4">
                <a href="tel:+919497454647" type="button" className="bttlight">  <FontAwesomeIcon size="lg" icon={ faPhone } className="mx-2" style={ { width:"16px" } }/> <span className="hidden md:inline-block	 lg:inline-block">Call Now</span></a>
            </div>
        );
    },
    /** embed more than one google drive folders at once 
     * 
     * Multiple
     * <gdrive items={
            [
                {folder:"1ePJkbjChvVYxEITp0UxJRgBy5LYb45qA", title:"Color Shifters", view:"grid"}
            ]
            }/>
     * 
     * Single:
       <gdrive folder="1ePJkbjChvVYxEITp0UxJRgBy5LYb45qA" 
       title="Color Shifters" view="grid" 
       style="width:100%; height:600px; border:0;"/>

    */
    gdrive: (props:any) => {
        return (
            props.items ? <>
                { props.items.map((obj: any, index: number) => (
                    <_gdriveEmbed key={ index } title={ obj.title } folder={ obj.folder } view={ obj.view } style={ obj.style || props.style }/>
                )) }
            </>
                : <_gdriveEmbed { ...props }/>
        );
    },
    tastable: (props:any) => {

        const classGrp0 = "px-6 py-4 text-center";
        const classGrp1 = "px-6 py-4 bg-gray-50 dark:bg-gray-800  text-center";
        const classF = "px-6 py-4 font-medium text-gray-900 whitespace-nowrap bg-gray-50 dark:text-white dark:bg-gray-800";
        const divStyle = "relative overflow-x-scroll shadow-md rounded-lg max-w-xs sm:max-w-max sm:visible";
        const rspboxes = props.data.responsiveboxes;
        return (
            <>
                { (rspboxes && rspboxes.length > 0) &&
                    <div className="flex flex-wrap place-content-center place-items-center">
                        { rspboxes.map((obj: any, rindex: number) => (
                            <div  key={ rindex } className={ divStyle + " lg:hidden m-6" }>
                                <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400  p-3 rounded-lg  border-gray-200 shadow-md dark:bg-gray-800 dark:border-gray-700 ">
                                    <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400  text-left">
                                        <tr>
                                            <th scope="col" className="px-6 py-4 bg-gray-50 dark:bg-gray-800 text-center border-b dark:border-b-yellow-700 dark:text-amber-500">
                                                { obj.header }
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        { obj.rows.map((row: any, trindex: number) => (
                                            <tr key={ trindex } className="border-b border-gray-200 dark:border-gray-700">
                                                <th key={ trindex } scope="row" className={ classGrp0  }>
                                                    { row }
                                                </th>
                                            </tr>
                                        )) }

                                    </tbody>
                                </table>
                            </div>
                        )) }
                        
                    </div>

                }
                <div className={ (rspboxes && rspboxes.length > 0) ?  (divStyle + " lg:block hidden") : divStyle }>
                    <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400 ">
                        <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400  text-left">
                            <tr>
                                { props.data.header.map((obj: any, index: number) => (
                                    <th key={ index } scope="col" className="px-6 py-4 bg-gray-50 dark:bg-gray-800 text-center border-b dark:border-b-yellow-700">
                                        { obj }
                                    </th>
                                )) }
                            </tr>
                        </thead>
                        <tbody>
                            { props.data.data.map((row: any, trindex: number) => (
                                <tr key={ trindex } className="border-b border-gray-200 dark:border-gray-700">
                                    { row.map((obj: any, thindex: number) => (
                                        <th  key={ thindex } scope="row" className={ thindex == 0 ? classF : (thindex%2) == 0 ?  classGrp1 : classGrp0 }>
                                            { obj }
                                        </th>
                                    )) }
                                </tr>
                            )) }
                        
                        </tbody>
                    </table>
                </div></>
        );
    }
};

/** Single embed of google drive content, single folder */
const _gdriveEmbed = (props:any) => <div className="flex flex-col mt-6 mb-6">
    <p><span className="flex flex-row  text-2xl goldtxt" >{ props.title }</span></p>
    <hr className="my-6 border-gray-200 dark:border-gray-700 "/>
    <iframe className="flex flex-row" title={ props.title } src={ "https://drive.google.com/embeddedfolderview?id=" + props.folder + "#"+ (props.view||"grid") } 
        style={ _merge({ border:0, height:"400px", width:"100%" },props.style)  }></iframe>
</div>;

const _merge = (one:any,two:any):any => {return { ...one, ...two };};
/** Spl component need to pass the parent page props to initialize, cannot be used statically */
export const ReferenceLinks = (props:any) =>  servicerefObj(props, props.parentprops);

export const servicerefObj = (props:any,parentprops:any) => {
    const { uid, type } = props;
    const uids:Array<string> = uid.split(",");
    /// look for objects in data
    const {
        data,
        pageContext
    } = parentprops;
    // eslint-disable-next-line no-unused-vars
    const { service, refservice, refprods, refdocs } = data;
         
    const objts = [];
    if(uids.indexOf(service.frontmatter.uid) != -1){
        objts[service.frontmatter.uid] = service;
    }
    const edges = [...refservice.edges, ...refprods.edges,...refdocs.edges];
    
    edges.map(({ node }) =>{
        const idx = uids.indexOf(node.frontmatter.uid);
        if(idx != -1){
            objts[node.frontmatter.uid] = node;
        }
    });
    
    return (
        <>
            { objts && Object.values(objts).map((node:any, index:number) =>
                type =="card" ? <a key={ index } href={ `/${node.fields.slug}` }  id={ `toast-${index}` }
                    className="p-4 pt-2 m-4 w-min flex items-center space-x-2  text-gray-500 bg-white rounded-lg shadow dark:text-gray-400 dark:divide-gray-700 dark:bg-gray-800 hover:border-yellow-600  border border-gray-600">
                    { node.frontmatter.heroimage && <GatsbyImage image={ getImage(node.frontmatter.heroimage.thumb) } 
                        className="flex h-12 w-20 rounded" /> }
                    <div className="inline-flex place-items-end pl-1 text-xl font-normal text-center text-gray-900 dark:text-amber-400"> 
                        <span className="text-gray-900 dark:text-amber-400 underline text-2xl">{ node.frontmatter.title }</span>
                        <img className="pl-2" alt={ "/"+ node.fields.slug } src="/img/newlinkicon.png" style={ { "height":"14px" } } />
                    </div>
                </a>
                    :<a className="p-4 pt-2 m-4" href={ "/"+ node.fields.slug }>
                        <span className="inline-flex place-items-end">
                            <span className="text-gray-900 dark:text-amber-400 underline text-2xl">{ node.frontmatter.title }</span>
                            <img className="pl-2" alt={ "/"+ node.fields.slug } src="/img/newlinkicon.png" style={ { "height":"14px" } } />
                        </span>
                    </a> 
                    
                // <a key={ index } href={ `/${node.fields.slug}` } className="mx-1 underline text-blue-400 hover:text-blue-600">{ node.frontmatter.title }</a>
            )
            }
        </>
    );
};

export const getServRefElement = (parentprops:any): any => {
    return {
        serviceref :(props:any) => servicerefObj(props,parentprops)
        
    };
};


export const PhotoGallary1 = ({ frontmatter }) => {
    // const {photosmeta}: {photosmeta: string[]} = frontmatter;
    const { photosmeta, photos, heroimage }: {photosmeta: string[],photos:Array<any> , heroimage:any  }  = frontmatter;
    const { title } = heroimage;
  
    const metamap = photosmeta == null ? null : photosmeta.reduce(function(map, strvalue) {
        const vals:string[] = strvalue.split("#");
        map[ vals[0] ] = _templateExc(vals[1] as string, tplobj);
        return map;
    }, {});

    const _getTitle = (filename:string) => metamap == null ? title :  metamap[filename] == null ? title : metamap[filename];

    return <section className="overflow-hidden  mt-10 ">
        <p className="ml-6 mb-2 text-slate-400">
            <i><sup>*</sup>click on image to zoom.</i>
        </p>
        <div className="grid grid-cols-2  md:grid-cols-4 lg:grid-cols-6 items-stretch pl-4 pr-4 justify-center place-items-center gap-2 w-full">

            <div className="flex  w-full">
                <GatsbyImage image={ getImage(heroimage.file) } className=" imgzoom block object-cover object-center w-full h-full rounded-lg border border-yellow-600"
                    alt={ title } />
            </div>
            { photos.map((obj: any, index: number) => (
                <div className="flex w-full"  key={ index }>
                    <GatsbyImage image={ getImage(obj) } alt={ _getTitle(obj.name) } title={ _getTitle(obj.name) }
                        className="imgzoom block object-cover object-center w-full h-full rounded-md border border-yellow-800" />
                </div>
            )) }


        </div>
    </section>;
};



function toImageGalVal(galval) {
    ///img/crystal-serum-audi.jpgx150#Gtechniq Crystal serum light Audi
    ///img/ceramic_coatings.jpg#TAS nano ceramic coating
    const imgparts0 = galval.split("#");
    const imgparts1 = imgparts0[0].split("x");

    const imgorg = imgparts1[0];
    const imgsize = imgparts1[1];

    const ext = imgorg.split(".").pop();
    const srcloc = imgsize == null ? imgparts0[0] :  imgorg.split("." + ext)[0] + "_thumb" + "_" + imgsize + "." + ext;

    return {
        src: imgorg,
        thumb: srcloc,
        alt: imgparts0[1]
    };
}

export const PhotoGallary2 = ({ frontmatter }) => <section className="overflow-hidden text-gray-700 mt-6 mb-6">
    <p className="ml-6 mb-2 text-slate-400">
        <i><sup>*</sup>click on image to zoom.</i>
    </p>
    <div className="flex flex-wrap flex-grow place-content-center place-items-center gap-2">
        { frontmatter.thumbgallery.map((thumb: any, index: number) => (
            <ThumbImg key={ index } { ...toImageGalVal(thumb) }/>
        )) }
    </div>
</section>;

export const ThumbImg = (props:any) =>  
    <div>
        <img src={ props.thumb } data-src={ props.src } title={ props.alt } alt={ props.alt }
            data-sizes="auto" className="imgzoom lazyload rounded border border-yellow-600" style={ { "width": "150px", "cursor": "pointer" } } />
        <p className="text-center text-gray-500 text-xs ml-1 mr-1"  style={ { "width": "150px" } }>{ props.alt }</p>
    </div>;
